<template>
  <div>
    <!-- modal -->

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(saveData)"
        @reset.prevent="resetForm"
      >
        <b-form-group>
          <b-form-checkbox
            id="is_active"
            v-model="brandData.is_active"
            class="custom-control-primary"
            name="check-button"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t('Aktif') }}
          </b-form-checkbox>

        </b-form-group>
        <validation-provider
          #default="validationContext"
          :name="$t('Marka Adı')"
          rules="required|min:2"
        >
          <b-form-group
            :label="$t('Marka Adı')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="brandData.name"
              autofocus
              autocomplete="off"
              :readonly="brandData.creator!=1"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <hr>
        <b-alert
          v-if="brandData.creator!=1"
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>{{ $t('Bu Veri Sistem Tarafından Otomatik Oluşturulduğu İçin Düzenlenemez ve Silinemez') }}</span>
          </div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-if="brandData.creator==1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            {{ $t('Kaydet') }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BButton, VBModal, BAlert, BFormInput, BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BAlert,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'modalShow',
    event: 'update:customer-manager-edit-modal-show',
  },

  props: {
    selectedBrand: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankBrandData: {
        name: '',
        is_active: 1,
        creator: 1,
      },
      componentMode: 'add',
      brandData: {},
      showManagerTitleEditor: false,
      selectedIndex: -1,
      createdBySystem: false,
    }
  },
  watch: {
  },
  created() {
    if (this.selectedBrand != null) {
      this.componentMode = 'edit'
    } else {
      this.componentMode = 'add'
    }
    if (this.selectedBrand != null) {
      this.blankBrandData = this.selectedBrand
    }
    this.resetBrandData()
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },

    saveData() {
      if (this.componentMode === 'add') {
        store.dispatch('app-product/addBrand', this.brandData)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.resetBrandData()
            this.$emit('dataChanged')
          })
      } else if (this.componentMode === 'edit') {
        store.dispatch('app-product/updateBrand', this.brandData)
          .then(() => {
            this.resetBrandData()
            this.$emit('dataChanged')
          })
      }
    },
    resetModal() {},
    resetBrandData() {
      this.brandData = JSON.parse(JSON.stringify(this.blankBrandData))
      this.selectedIndex = -1
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const onSubmit = () => {
    }
    const resetBrandData = () => {
      this.brandData.value = JSON.parse(JSON.stringify(this.blankBrandData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBrandData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
